import HttpService from "./HttpService"

class ConsentsService extends HttpService {
  getUserConsents(req?: any) {
    return this.get("/api/tnet/popup", {}, null, req)
  }

  postUserConsents(body?: any, req?: any) {
    return this.post("/api/tnet/popup", body, null, req)
  }
}

export default new ConsentsService()
