import { useEffect, useState } from "react"
import Image from "next/image"
import { Dialog } from "@headlessui/react"

import ModalWrapper from "../Modal/ModalWrapper"
import Typography from "../typography"
import Checkbox from "../common/form-controll/Checkbox/Checkbox"
import Button from "../common/Button/Button"
import { Controller, useForm } from "react-hook-form"
import { IConsent, ConsentProduct, Consent, IOffer, IProduct } from "src/types/consentType"
import useConsents from "src/server/queryHooks/useConsents"

interface Props {
  setStep: (step: string) => void
  consentData: IConsent
  consent: any
  setConsent: any
}

const PartnerCompaniesModal = ({ setStep, consentData, consent, setConsent }: Props) => {
  const { control, watch, setValue } = useForm()
  const { userPostConsentMutation } = useConsents()

  const PartnerProductsOffers: any = consentData.offers[1].products

  const [isOpen, setIsOpen] = useState(true)
  const [allAccepted, setAllAccepted] = useState(false)

  const productCheckboxes = consent.offers[1].products.flatMap((product: IProduct) => `partner-product-${product.uuid}`)
  const allCheckboxStates = watch(productCheckboxes)

  useEffect(() => {
    const allProductsAccepted = consent.offers.every((item: Consent) => {
      if (item.products) {
        if (item.scope === consent.offers[1]?.scope) {
          return item.products.every((product: any) => product.accept === true)
        }
      }
      return true
    })

    setAllAccepted(allProductsAccepted)
    setValue("partner-products-all", allProductsAccepted)
  }, [consent])

  const allChecked = allCheckboxStates.every((checked: boolean) => allAccepted)

  const handleProductChange = (uuid: number, isChecked: boolean) => {
    watch(productCheckboxes).filter((checked: boolean) => checked).length

    const updatedConsent = {
      offers: consent.offers.map((offer: Consent) => ({
        ...offer,
        products: offer.products.map((product: ConsentProduct) =>
          product.uuid === uuid ? { ...product, accept: isChecked } : product
        )
      }))
    }

    setConsent(updatedConsent)
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked

    productCheckboxes.forEach((uuid: number) => {
      setValue(uuid.toString(), isChecked)
    })

    const updatedConsent = {
      offers: consent.offers.map((item: Consent, index: number) => ({
        ...item,
        products:
          index === 1
            ? item.products.map((product: ConsentProduct) => ({
                uuid: product.uuid,
                accept: isChecked
              }))
            : item.products
      }))
    }

    setConsent(updatedConsent)
  }

  const handleAgreement = () => {
    if (!userPostConsentMutation.isLoading) {
      userPostConsentMutation.mutate(consent)
      setIsOpen(false)
    }
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      width="664px"
      className="z-100 h-[600px] justify-start overflow-y-auto bg-white py-0 pb-0 text-start md:h-auto md:max-h-[664px]"
    >
      <Dialog.Title className={`sticky top-0 z-10 mt-0 flex gap-5 bg-white py-4 md:pt-8`}>
        <Image
          src="/images/arrow_back_ios.svg"
          width={12}
          height={20}
          alt="arrow"
          onClick={() => setStep("ModifyTnetProducts")}
          className="cursor-pointer"
        />
        <Typography type="h5" weight="medium">
          {consentData.offers[1].title}
        </Typography>
      </Dialog.Title>
      <div className="custom-scroll flex w-full flex-1 flex-col items-center gap-4  overflow-y-auto md:h-[474px] md:flex-row md:flex-wrap">
        <div
          key={"All Products"}
          className="flex h-14 w-full items-center justify-between rounded-xl border border-slate-300"
        >
          <Typography type="text" weight="medium" className="p-4">
            ყველას მონიშვნა
          </Typography>
          <Controller
            name={`partner-products-all`}
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <Checkbox
                className="mr-4 w-5"
                selected={allChecked}
                label=""
                {...field}
                onChange={(e: any) => {
                  field.onChange(e)
                  handleSelectAllChange(e)
                }}
              />
            )}
          />
        </div>
        {PartnerProductsOffers.map((product: ConsentProduct) => (
          <div
            className="flex w-full items-center justify-between rounded-xl border border-slate-300"
            key={product.uuid}
          >
            <Typography type="text" weight="medium" className="p-[18px]">
              {product.title}
            </Typography>
            <Controller
              name={`partner-product-${product.uuid}`}
              control={control}
              defaultValue={allChecked}
              render={({ field }) => (
                <Checkbox
                  className="mr-4 w-5"
                  selected={allChecked ? true : field.value}
                  label=""
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.checked)
                    handleProductChange(product.uuid, e.target.checked)
                  }}
                />
              )}
            />
          </div>
        ))}
      </div>
      <div className="sticky bottom-0 z-10 flex w-full justify-end bg-white py-4 md:pb-8">
        <Button
          text="ვეთანხმები"
          intent={"primary"}
          className="w-full px-10 py-1 md:w-auto"
          onClick={handleAgreement}
        />
      </div>
    </ModalWrapper>
  )
}

export default PartnerCompaniesModal
