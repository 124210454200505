import { useMutation } from "@tanstack/react-query"
import useOriginUrl from "src/hooks/useOriginUrl"
import useUser from "src/hooks/useUser"
import useNotifications, { postSubscribeCompany, postUnsubscribeCompany } from "src/server/queryHooks/useNotifications"
import { useRouter } from "next/router"

import Image from "next/image"
import { CardPin, CompanyBody, CompanyCardBox, CompanyImage } from "src/views/components/Cards/Card.styles"
import Typography from "../typography"

import { cx } from "class-variance-authority"
import { useEffect, useState } from "react"
import { ACCESS_TOKEN_NAME, STATIC_URL } from "src/env"
import Cookie from "src/utils/configs/Cookie"
import { SwiperSlide } from "swiper/react"
import { CompanyCardProps } from "../../../types/card"
import { CompanyApi } from "../../../types/commonTypes"
import { CompanyHeader } from "./Card.styles"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"
import Link from "next/link"

type NotificationStates = {
  [companyId: number]: boolean
}

const CompanyCard = ({
  icon,
  title,
  industry,
  city,
  employee_count,
  vacancies_count,
  subscriber_count,
  comapny_id,
  has_logo,
  company_id,
  index = 4,
  logo_version
}: CompanyCardProps) => {
  const [notificationStates, setNotificationStates] = useState<NotificationStates>({})

  const { initiateLogin } = useOriginUrl()

  const { connectedUser } = useUser()

  const { subscriptionsQuery } = useNotifications()

  const router = useRouter()

  const subscribedCompanyIds = subscriptionsQuery.data

  const subscribeCompanyMutation = useMutation({
    mutationFn: postSubscribeCompany,
    onSuccess: () => {
      subscriptionsQuery.refetch()
    }
  })

  const unsubscribeCompanyMutation = useMutation({
    mutationFn: postUnsubscribeCompany,
    onSuccess: () => {
      subscriptionsQuery.refetch()
    }
  })

  const isLoading =
    unsubscribeCompanyMutation.isLoading ||
    subscribeCompanyMutation.isLoading ||
    subscriptionsQuery.isLoading ||
    subscriptionsQuery.isFetching

  useEffect(() => {
    if (Array.isArray(subscribedCompanyIds)) {
      const initialState: NotificationStates = {}
      subscribedCompanyIds.forEach(companyId => {
        initialState[companyId] = true
      })
      setNotificationStates(initialState)
    }
  }, [subscribedCompanyIds])

  const onClick = (companyId: number) => {
    if (!connectedUser) {
      initiateLogin()
    }

    if (!isLoading) {
      if (subscribedCompanyIds?.includes(companyId)) {
        unsubscribeCompanyMutation.mutate(companyId)

        setNotificationStates(prevState => ({
          ...prevState,
          [companyId]: !prevState[companyId] // Toggle the subscription status
        }))
      } else {
        subscribeCompanyMutation.mutate(companyId)

        setNotificationStates(prevState => ({
          ...prevState,
          [companyId]: !prevState[companyId]
        }))
      }
    }
  }

  const handleCompanyCardClick = (companyId: number) => {
    router.push(`/vacancy?company_id=${companyId}`)
  }

  const generateIndex = () => {
    switch (index % 4) {
      case 0:
        return "https://play-lh.googleusercontent.com/jDZey_jeFRaVFKRBgaS3mWtrEP8S8DPRl-B4YijVGf-OxcN6dAB8j7AyDddlvAZN4nOM=w240-h480-rw"
      case 1:
        return "/images/tnet.jpeg"
      case 2:
        return "https://logowik.com/content/uploads/images/adidas-new-20225326.jpg"
      case 3:
        return "https://play-lh.googleusercontent.com/bJd_taaz0uqR58VIxifJfATTPjbmynzcQKeTP-JDRYhmBB6yTr8ny8evfydcWDyTG5eL=w240-h480-rw"
      default:
        return icon
      // code block
    }
  }

  return (
    <CompanyCardBox>
      <CompanyHeader>
        <CompanyImage>
          <CompanyLogo has_logo={has_logo} logo_version={logo_version} company_id={company_id} />
        </CompanyImage>
        <div
          className={cx(
            "cursor-pointer rounded-full p-1.5 transition-all",
            notificationStates[company_id] ? "bg-primary-5 hover:bg-primary-10" : "bg-neutral-10 hover:bg-neutral-20",
            Cookie.get(ACCESS_TOKEN_NAME) ? "visible" : "invisible"
          )}
          onClick={() => onClick(company_id)}
        >
          <Image
            src={`/images/notifications_${!notificationStates[company_id] ? "muted" : "active"}.svg`}
            alt={"notifications"}
            width={24}
            height={24}
          />
        </div>
      </CompanyHeader>
      <Link href={`/vacancy?company_id=${comapny_id}`}>
        <Typography type="h4" className="mt-4 line-clamp-1 hover:text-primary-100">
          {title}
        </Typography>
      </Link>
      <CompanyBody>
        <Typography type="text">{industry}</Typography>
        <Typography type="small">
          {city ? `${city} ` : ""}
          {/* {country} */}
        </Typography>

        <CardPin>
          <Image src="/images/people.svg" width={20} height={20} alt="lari" />
          <Typography type="small">
            <span className="font-tbcx-medium">{employee_count}</span> დასაქმებული
          </Typography>
        </CardPin>

        <CardPin>
          <Image src="/images/cases.svg" width={20} height={20} alt="watch_later" />

          <Typography
            type="small"
            onClick={() => handleCompanyCardClick(comapny_id)}
            className="cursor-pointer hover:text-primary-100"
          >
            <span className="font-tbcx-medium">{vacancies_count}</span> ვაკანსია
          </Typography>
        </CardPin>
        <CardPin>
          <Image src="/images/notifications.svg" width={20} height={20} alt="work_outline" />
          <Typography type="small">
            <span className="font-tbcx-medium">{subscriber_count}</span> გამომწერი
          </Typography>
        </CardPin>
      </CompanyBody>
    </CompanyCardBox>
  )
}

interface CompanyCardsProps {
  data: CompanyApi[]
}
const CompanyCards = ({ data }: CompanyCardsProps) => {
  return (
    <>
      {data.map((company, index) => (
        <SwiperSlide className="top-vacancy-box" key={index}>
          <CompanyCard
            comapny_id={company.id}
            key={company.id}
            index={index}
            icon={"/images/gulf.svg"}
            title={company?.brand_name}
            industry={company.industry_title ? company?.industry_title : "\u00A0"}
            country={""}
            city={company.legal_city_title ? company.legal_city_title : "\u00A0"}
            has_logo={company?.has_logo}
            company_id={company.id}
            employee_count={company?.employee_count}
            vacancies_count={company?.active_vacancies}
            subscriber_count={company?.subscribers_count}
            logo_version={company?.logo_version}
          />
        </SwiperSlide>
      ))}
    </>
  )
}
CompanyCards.displayName = "SwiperSlide"
export default CompanyCards
