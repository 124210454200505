import parse from "html-react-parser"

interface IAgreement {
  title: string
  href: string
}

export const ReplaceTextWithLinks = (agreements: IAgreement[], paragraph: string) => {
  const ourAgreement = agreements[0]
  const ourPartnersAgreement = agreements[1]

  const firstParagraphDocument = paragraph
    .replace(
      ourAgreement?.title ?? "",
      `<a href=${ourAgreement?.href ?? ""} target="_blank" className="underline">${ourAgreement?.title}</a>`
    )
    .replace(
      ourPartnersAgreement?.title ?? "",
      `<a href=${ourPartnersAgreement?.href ?? ""} target="_blank" className="underline">${ourPartnersAgreement?.title}</a>`
    )

  return parse(firstParagraphDocument ?? "")
}
