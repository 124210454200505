import { useEffect, useState } from "react"
import ModalWrapper from "../Modal/ModalWrapper"
import Checkbox from "../common/form-controll/Checkbox/Checkbox"
import { Dialog } from "@headlessui/react"
import Typography from "../typography"
import Image from "next/image"
import { Controller, useForm } from "react-hook-form"
import Button from "../common/Button/Button"
import { IConsent, Consent, ConsentProduct, IOffer, IProduct, ISendUserConsent } from "src/types/consentType"
import useConsents from "src/server/queryHooks/useConsents"

import { ReplaceTextWithLinks } from "src/utils/helpers/consents/ReplaceTextWithLinks"

interface Props {
  consentData: IConsent
}

const ShowAgainConsentModal = ({ consentData }: Props) => {
  const { control } = useForm()

  const TNETProductsOffers: any = consentData.offers[0].products

  const [consent, setConsent] = useState<any>()

  const { userPostConsentMutation } = useConsents()

  useEffect(() => {
    const initialOffers = consentData.offers.map(item => ({
      scope: item.scope,
      priority: item.priority,
      products: item.products.map(product => ({
        uuid: product.uuid,
        accept: false
      }))
    }))

    setConsent({ offers: initialOffers })
  }, [setConsent])

  const [isOpen, setIsOpen] = useState(true)
  const [allChecked, setAllChecked] = useState(false)

  const handleProductChange = (uuid: number, isChecked: boolean) => {
    const updatedConsent = {
      offers: consent.offers.map((offer: Consent) => ({
        ...offer,
        products: offer.products.map((product: ConsentProduct) =>
          product.uuid === uuid ? { ...product, accept: isChecked } : product
        )
      }))
    }

    setConsent(updatedConsent)
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setAllChecked(isChecked)

    const updatedConsent = {
      offers: consent.offers.map((offer: Consent) => ({
        ...offer,
        products: offer.products.map((product: ConsentProduct) => ({
          ...product,
          accept: isChecked
        }))
      }))
    }

    setConsent(updatedConsent)
  }

  const handleRejectAll = () => {
    if (Object.keys(consent).length > 0 && !userPostConsentMutation.isLoading) {
      const rejectAll: ISendUserConsent = {
        offers: consent.offers.map((item: IOffer) => ({
          ...item,
          products: item.products.map((product: IProduct) => ({
            uuid: product.uuid,
            accept: false
          }))
        }))
      }
      userPostConsentMutation.mutate(rejectAll)
      setIsOpen(false)
    }
  }

  const handleAgreement = () => {
    if (!userPostConsentMutation.isLoading) {
      userPostConsentMutation.mutate(consent)
      setIsOpen(false)
    }
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      width="664px"
      height="md:h-570px"
      className="z-100 h-[600px] justify-start bg-white p-4 pt-0 text-start md:h-auto md:px-8 md:pt-6"
    >
      <Dialog.Title className={`sticky top-0 z-10 flex gap-4 bg-white py-4 md:pt-0`}>
        <Typography type="h5" weight="medium">
          {consentData.offers[0].title}
        </Typography>
      </Dialog.Title>
      <div className="custom-scroll flex w-full flex-1 flex-col items-center gap-4 overflow-scroll pb-4 md:flex-row md:flex-wrap">
        <div className="mb-4 flex flex-col gap-4 md:mb-5">
          <Typography type="text">{ReplaceTextWithLinks(consentData.agreements, consentData.document[0])}</Typography>
          <Typography type="text" className="">
            {consentData.document[1]}
          </Typography>
          <Typography type="text">
            {consentData.document[2]}
            <u>
              <a href={consentData.agreements[2].href} className="cursor-pointer pl-2" target="_blank">
                {consentData.agreements[2].title}
              </a>
            </u>
          </Typography>
        </div>
        <div
          key={"All Products"}
          className="flex h-14 w-full items-center justify-between rounded-xl border border-slate-300 md:w-[calc(50%-0.5rem)]"
        >
          <Typography type="text" weight="medium" className="p-4">
            ყველას მონიშვნა
          </Typography>
          <Controller
            name={`product-all`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                className="mr-4 w-5"
                selected={allChecked}
                label=""
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e)
                  handleSelectAllChange(e)
                }}
              />
            )}
          />
        </div>
        {TNETProductsOffers.map((product: any) => (
          <div
            key={product.uuid}
            className="flex h-14 w-full items-center justify-between rounded-xl border border-slate-300 md:w-[calc(50%-0.5rem)]" // Set a fixed height (adjust as needed)
          >
            <Image
              src={product.imageURL}
              width={96}
              height={24}
              alt={product.title}
              className="h-full p-4"
              style={{ objectFit: "contain" }}
            />
            <Controller
              name={`product-${product.uuid}`}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  className="mr-4 w-5"
                  selected={allChecked ? true : field.value}
                  label=""
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.checked)
                    handleProductChange(product.uuid, e.target.checked)
                  }}
                />
              )}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-col flex-col-reverse justify-end justify-end bg-white	pt-4 sm:flex-row sm:gap-5 md:p-0">
        <div className="flex gap-3">
          <Button
            text="არ ვეთანხმები"
            intent={"ghost"}
            weight={"medium"}
            className="w-full px-0 text-secondary-50 hover:bg-transparent sm:w-auto"
            onClick={handleRejectAll}
          />
        </div>
        <div className="">
          <Button
            text="ვეთანხმები"
            intent={"primary"}
            className="w-full cursor-pointer px-10 py-1"
            onClick={handleAgreement}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ShowAgainConsentModal
