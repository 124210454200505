import dynamic from "next/dynamic"
import Link from "next/link"
import { FC } from "react"
import { CategoryCardItem } from "src/views/components/Cards/Card.styles"
import Typography from "../typography"
import { IPublicCategoryResponse } from "../../../types/commonTypes"

const HorizontalScrollDiv = dynamic(() => import("src/utils/helpers/HorizontallScroll"))

export interface CategoryCardProps {
  title: string
  vacancy_count: number
  categor_id: number
  index: number
  icons: string
}

const CategoryCard: FC<CategoryCardProps> = ({ title, icons, categor_id, index, vacancy_count }) => {
  // Function to convert SVG content to data URL
  const convertToDataURL = (svgContent: any) => {
    const svgBlob = new Blob([svgContent], { type: "image/svg+xml" })
    const url = URL.createObjectURL(svgBlob)
    return url
  }

  return (
    <Link href={`/vacancy/?categories=${categor_id}`} key={index}>
      <CategoryCardItem>
        <div className="flex h-7 w-7 items-center">
          <div dangerouslySetInnerHTML={{ __html: icons }}></div>
        </div>
        <Typography type="button" weight="bold" color="dark" className="line-clamp-1">
          {title}
        </Typography>
        <Typography type="text">{vacancy_count} ვაკანსია</Typography>
      </CategoryCardItem>
    </Link>
  )
}

interface CategoryCardsProps {
  data: IPublicCategoryResponse[]
}
const CategoryCards = ({ data }: CategoryCardsProps) => {
  return (
    <HorizontalScrollDiv className="md:px-auto no-scrollbar relative flex select-none gap-5 px-4 pb-10 pt-2 md:px-0 md:pb-14">
      {/* <div className='mt-20 flex select-none gap-5 overflow-hidden'> */}
      {data.map((categories, index) => (
        <CategoryCard
          key={categories.id}
          categor_id={categories.id}
          index={index}
          title={categories.title}
          vacancy_count={categories.count}
          icons={categories.icons}
        />
      ))}
      {/* </div> */}
    </HorizontalScrollDiv>
  )
}

export default CategoryCards
